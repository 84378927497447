<template>
  <div class="wuliaoDetail">
    <van-nav-bar
      left-arrow
      fixed
      safe-area-inset-top
      placeholder
      title="物料详情"
      @click-left="leftClick"
    />
    <div v-if="isLoading">
      <center id="loadingbox">
        <van-loading size="68px" />
      </center>
    </div>
    <div class="body" v-if="!isLoading">
      <div class="top">
        <div style="font-size: 32px; font-weight: 500">
          {{ GLOBAL }}检测中心
        </div>
        <div class="flex_between">
          <div>Testing Center</div>
          <div>检测编号: {{ incomingDetail.incomingNo }}</div>
        </div>
        <div class="line"></div>
        <div class="flex_between">
          <div>物流单号: {{ incomingDetail.expressNo ? incomingDetail.expressNo : "自送" }}</div>
          <div>
            检测日期: {{ incomingDetail.created | formatDate_("yyyy-MM-dd") }}
          </div>
        </div>
      </div>
      <div v-for="(items, indexs) in incomingDetail.subDetails" :key="indexs">
        <div
          class="detail flex"
          v-for="(item, index) in items.content"
          :key="index"
        >
          <div class="left">
            <div class="block">
              <div class="lable">检测物:</div>
              <div class="value">{{ item.goodsName }}</div>
            </div>
            <div class="block">
              <div class="lable">折足(g):</div>
              <div class="value">{{ item.discountWeight }}</div>
            </div>
            <div class="block">
              <div class="lable">预估含量(%):</div>
              <div class="value">{{ item.estimatePercent }}</div>
            </div>
          </div>
          <div class="middle">
            <div class="block">
              <div class="lable">净重(g):</div>
              <div class="value">{{ item.afterWeight }}</div>
            </div>
            <div class="block">
              <div class="lable">含量(%):</div>
              <div class="value">{{ item.percent }}</div>
            </div>
          </div>
          <div class="right">
            <van-swipe indicator-color="red" :width="220">
              <template v-if="item.images && item.images.length !== 0">
                <van-swipe-item
                  v-for="(img, imgIndex) in item.images"
                  :key="imgIndex"
                >
                  <img :src="img.url" alt="" />
                </van-swipe-item>
              </template>
              <template v-else>
                <van-swipe-item>
                  <img src="@/assets/images/pho_com_nor@2x.png" alt="" />
                </van-swipe-item>
              </template>
            </van-swipe>
          </div>
        </div>
      </div>
      <div class="detail flex">
        <div class="left">
          <div class="block">
            <div class="lable">来料重量(g):</div>
            <div class="value">{{ incomingDetail.totalWeight }}</div>
          </div>
          <div class="block">
            <div class="lable">快递费:</div>
            <div class="value">{{ incomingDetail.fee }}</div>
          </div>
          <div class="block">
            <div class="lable">提纯费:</div>
            <div class="value">{{ incomingDetail.purificationFee }}</div>
          </div>
        </div>
        <div class="middle">
          <div class="block">
            <div class="lable">折足重量(g):</div>
            <div class="value">{{ incomingDetail.totalDiscountWeight }}</div>
          </div>
          <div class="block">
            <div class="lable">保险费:</div>
            <div class="value">{{ incomingDetail.insurance}}</div>
          </div>
        </div>
        <div class="right kefu">
          <div class="kefuwechat">
            <img :src="kefuImg" alt="" style="width: 60px; height: 60px" />
          </div>
          <div>客服微信</div>
          <div class="text">如对检测结果存在异议请尽快联系客服</div>
          <div class="flex" style="margin-top: 2px;">
            <img
              src="@/assets/images/icon_phone@2x.png"
              alt=""
              style="height: 12px; width: 12px; margin-top: 2px"
            />
            <div class="phone">18688828557</div>
          </div>
        </div>
      </div>
      <el-button class="btnsee f32" @click="makeSure" :disabled="incomingDetail.referenceStatus == 'CONFIRMED' || incomingDetail.referenceStatus == 'TICHUN' || incomingDetail.referenceStatus == 'TICHUNING' " v-if="incomingDetail.referenceStatus !== 'REJECT'">{{incomingDetail.referenceStatus !== 'WAIT_CONFIRM' && incomingDetail.referenceStatus !== 'NONE' ? '已确认' : '确认无误'}}</el-button>
    </div>
    <!-- 平仓明细 -->
    <div v-if="incomingDetail.referenceInfoList && backPath === 'incoming'" class="referenceInfo">
      <div class="costCalculation">
        <div class="icon"></div>
        <h3>引用明细</h3>
      </div>
      <div class="list">
        <div
          class="listContent flex_between" style="height: 34px;line-height: 34px;"
        >
          <div class="time">引用时间</div>
          <div class="price">黄金</div>
          <div class="price">白银</div>
          <div class="price">铂金</div>
          <div class="price">钯金</div>
          <div class="price">结算类型</div>
        </div>
        <div
          class="listContent flex_between" style="height: 34px;line-height: 34px;"
          v-for="(item, index) in incomingDetail.referenceInfoList"
          :key="index"
        >
          <div class="time">{{ item.time | formatDate_("yyyy-MM-dd") }}</div>
          <div class="price">{{ item.goldReferenceWeight }}</div>
          <div class="price">{{ item.baiyinReferenceWeight }}</div>
          <div class="price">{{ item.ptReferenceWeight }}</div>
          <div class="price">{{ item.pdReferenceWeight }}</div>
          <div class="price">{{ item.openingType === "SELL" ? "卖出" : item.openingType === "MORTGAGE" || item.openingType === "PRE_MORTGAGE" ? "存料" : item.openingType === "CHANGE_MATERIAL" ? "换料" : item.openingType}}</div>
        </div>
      </div>
    </div>
    <div class="tip">
      <div>检测依据：GB/T 16554 GB/T 18043 GB/T 7724 GB/T 11887</div>
      <div>计量标准：GB/T 7724-2008</div>
      <div>国家标准：GB 11887-2002规定</div>
      <div>光普检测标准火试法：GB/T 18043-2013</div>
    </div>
  </div>
</template>

<script>
import { getAboutPhoto, getIncomingDetail, incomingSure } from "@/utils/api";
import { formatDate_ } from "@/utils/filters";

export default {
  data() {
    return {
      isLoading: true, //加载状态
      kefuImg: "",
      incomingDetail: {},
      statementId: "",
      backPath: "incoming",
    };
  },
  filters: {
    formatDate_
  },
  mounted() {
    const incomingId = this.$route.query.incomingId;
    this.statementId = this.$route.query.oid;
    this.backPath = this.$route.query.type;
    getIncomingDetail(incomingId).then(res => {
      this.incomingDetail = res;
      this.isLoading = false;
    });
    getAboutPhoto().then(res => {
      res.forEach(item => {
        if (item.eventType == "KEFU") this.kefuImg = item.img;
      });
    });
  },
  methods: {
    leftClick() {
      if (this.backPath === "incoming") {
        this.$router.push({
          name: "incoming"
        });
      } else {
        this.$router.push({
          name: "selldetails",
          query: { orderid: this.statementId }
        });
      }
    },
    makeSure() {
      const that = this
      incomingSure(this.incomingDetail.id).then(res => {
        this.$toast.success({
          message: "确认成功",
          duration: 1500,
          onClose: () => {
            that.leftClick()
          }
        })
      })
    }
  }
};
</script>

<style lang="scss" scoped="true">
.wuliaoDetail {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  .body {
    margin: 30px 29px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    .top {
      background: #ffdede;
      border-radius: 12px 12px 0px 0px;
      padding: 30px 40px;
      color: #cc0e0f;
      font-size: 24px;
      .line {
        height: 0px;
        opacity: 1;
        border: 1px solid #cc0e0f;
        margin: 12px 0;
      }
    }
    .detail {
      padding: 30px 40px;
      border-bottom: 1px solid #cccccc;
      .left {
        width: 230px;
      }
      .middle {
        margin-left: 20px;
        width: 142px;
      }
      .right {
        margin-left: 18px;
        width: 220px;
        height: 254px;
      }
      .block {
        margin-top: 16px;
        .lable {
          font-size: 20px;
          color: #999999;
          font-weight: 500;
        }
        .value {
          margin-top: 6px;
          font-size: 30px;
          font-weight: 500;
          color: #242424;
        }
      }
    }
    .kefu {
      text-align: center;
      color: #999999;
      font-size: 20px;
      .text {
        text-align: left;
        margin-top: 6px;
      }
      .phone {
        font-size: 24px;
        color: #242424;
        margin-left: 8px;
      }
    }
    .f32 {
      font-size: 32px;
    }
    .btnsee {
      margin: 20px 95px;
      border: 1px solid #FF2D2E;
      color: #fff;
      width: 480px;
      height: 64px;
      background: #FF2D2E;
      border-radius: 32px;
    }
  }
  .tip {
    margin-top: 30px 85px;
    font-size: 20px;
    text-align: center;
    color: #cccccc;
  }
}
::v-deep {
  .van-swipe-item {
    img {
      width: 220px;
      height: 254px;
    }
  }
  .van-nav-bar {
    height: 80px;
    .van-nav-bar__content {
      line-height: 80px;
      height: 80px;
      .van-nav-bar__title {
        font-size: 34px;
      }
      .van-icon {
        font-size: 36px;
        color: #1a1a1a;
      }
    }
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.costCalculation{
  padding: 10px;
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid #d8d8d8;
  .icon{
    width: 30px;
    height:30px;
    border-radius: 50%;
    background-color: #ff2d2e;
    margin-right: 20px;
  }
  h3{
    font-size: 28px;
    color: #333333;
  }
}
.list {
  background: #ffffff;
  .flex_between {
    display: flex;
    justify-content: space-between;
  }
  .listContent {
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
    font-size: 22px;
    .time {
      width: 180px;
    }
    .price {
      width: 140px;
    }
  }
}
.referenceInfo {
  margin: 20px 29px;
}
</style>
